<template>
  <main id="searchTariffPage">
    <b-row>
      <b-col>
        <b-form
          @submit.prevent="search"
          inline
          class="d-flex justify-content-center"
        >
          <b-form-select
            id="inline-form-custom-select-dist"
            class="m-2"
            v-model="distribuidoraSelected"
            :options="distribuidorasList"
            placeholder="Distribuidora"
          />
          <b-form-select
            id="inline-form-custom-select-th"
            class="m-2"
            v-model="t_horosazonal.selected"
            :options="t_horosazonal.options"
            placeholder="Tarifa Horosazonal"
          />

          <b-form-select
            id="inline-form-custom-select-ct"
            class="m-2"
            v-model="c_tensao.selected"
            :options="c_tensao.options"
            placeholder="Classe Tensão"
          />

          <b-button variant="primary" type="submit">{{
            $t('regulated_market.search')
          }}</b-button>
        </b-form>

        <h4
          v-if="!this.isValue && !this.loading"
          class="text-center p-4"
          style="margin-top: 50px; margin-bottom: 25px"
        >
          {{ $t('regulated_market.not_found_message') }}
        </h4>
        <div v-if="this.loading" class="text-center p-2">
          <h4>{{ $t('regulated_market.loading') }}</h4>
          <b-spinner v-if="this.loading" label="Spinning"></b-spinner>
        </div>

        <div v-if="this.isValue && !this.loading" class="p-2">
          <h4 class="text-center p-4">{{ distributor.name }}</h4>

          <b-row>
            <b-col class="col-encargos" md="auto">
              <ChartEncargos :distributor="distributor"></ChartEncargos>
            </b-col>
            <b-col class="col-tusd">
              <ChartTarifa :distributor="distributor"></ChartTarifa>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import ChartEncargos from '../../components/chartEncargos';
import t_horosazonal from '../../utils/t.horosazonal';
import c_tensao from '../../utils/c.tensao';
import ChartTarifa from '../../components/chartTarifa';
import VueToast from 'vue-toast-notification';
import dayjs from 'dayjs';
import Vue from 'vue';

import {
  filtraTarifasFromParameters,
  getTariff,
  extractDistributorFromTarrifs,
  computeQuadro
} from './regulatedMarket.services';
import { i18n } from '../../main';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

Vue.use(VueToast);

dayjs.locale('pt-br');

export default {
  name: 'SearchTariffPage',
  components: {
    ChartEncargos,
    ChartTarifa
  },
  data() {
    return {
      isValue: false,
      loading: false,
      title: i18n.tc('regulated_market.title'),
      distributor: null,
      t_horosazonal: t_horosazonal,
      c_tensao: c_tensao,
      distribuidorasList: [],
      distribuidoraSelected: null,
      tariff: []
    };
  },
  methods: {
    getListaTarifasEDistribuidoras() {
      getTariff().then((tarifas) => {
        this.tariff = tarifas;

        this.distribuidorasList = extractDistributorFromTarrifs(this.tariff);

        this.distribuidoraSelected = this.distribuidorasList[0].value;
      });
    },

    search() {
      const horosazonal = this.t_horosazonal.selected;
      const tensao = this.c_tensao.selected;
      const distrib = this.distribuidoraSelected;
      const tariffList = this.tariff;
      this.loading = true;

      const distributorInfo = filtraTarifasFromParameters(
        tariffList,
        distrib,
        horosazonal,
        tensao
      );

      const finalDistributor = computeQuadro(distributorInfo);

      this.distributor = finalDistributor;
      this.isValue = finalDistributor.temValor;
      this.loading = false;
    }
  },

  computed: {
    breadcrumb: () => [
      {
        text: 'Dashboards',
        href: '/'
      },
      {
        text: i18n.tc('regulated_market.title'),
        active: true
      }
    ]
  },
  created() {
    this.getListaTarifasEDistribuidoras();
  }
};
</script>

<style scoped>
.col-tusd,
.col-encargos {
  background-color: #fff;
  width: 48%;
}

@media (max-width: 768px) {
  .col-tusd,
  .col-encargos {
    background-color: #fff;
    width: 100%;
  }
}
</style>

<template>
  <main id="groupedTariffMarket">
    <PageHeader
      :title="$t('regulated_market.grouped_tariff_menu')"
      :items="breadcrumb"
    />

    <div>
      <b-modal
        v-if="modalContentData != null"
        id="unidades-modal"
        centered
        v-bind:title="$t('regulated_market.companies')"
        title-class="font-18 centered"
        ok-only
        ok-variant="success"
      >
        <b-table
          v-if="modalContentData.unidades.length > 0"
          striped
          responsive
          hover
          :items="modalContentData.unidades"
          :fields="unidadesAtendidasFields"
          style="font-size: 90%"
        >
        </b-table>
      </b-modal>
    </div>

    <div
      class="distributor-wrapper"
      v-for="distributor in groupedDistributors"
      :key="distributor.componentKey"
    >
      <b-row>
        <b-col>
          <b-card>
            <h4 v-if="!isValue && !loading" class="text-center p-4">
              {{ $t('regulated_market.not_found_message') }}
            </h4>
            <div v-if="loading" class="text-center p-2">
              <h4>{{ $t('regulated_market.loading') }}</h4>
              <b-spinner v-if="loading" label="Spinning"></b-spinner>
            </div>

            <div v-if="isValue && !loading">
              <b-row>
                <b-col>
                  <h4 class="text-left" style="font-weight: bold">
                    Distribuidora: {{ distributor.name }}
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5 class="text-left">
                    Tarifa Horozasonal:
                    <span
                      class="badge rounded-pill font-size-15"
                      :class="{
                        'badge-soft-success':
                          distributor.tarifaHorosazonal === 'VERDE',
                        'badge-soft-warning':
                          distributor.tarifaHorosazonal === 'Pending',
                        'badge-soft-primary':
                          distributor.tarifaHorosazonal === 'AZUL',
                        'badge-soft-secondary':
                          distributor.tarifaHorosazonal === 'Waiting'
                      }"
                      >{{ distributor.tarifaHorosazonal }}</span
                    >
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5 class="text-left">
                    Classe de Tensão:
                    <span
                      class="
                        badge
                        rounded-pill
                        font-size-15
                        badge-soft-secondary
                      "
                      >{{ distributor.classeTensao }}</span
                    >
                  </h5>
                </b-col>
              </b-row>

              <b-row style="margin-top: 25px; margin-bottom: 20px">
                <b-col>
                  <div class="float-left">
                    <b-button
                      variant="success"
                      v-b-modal.unidades-modal
                      @click="refreshModalData(distributor)"
                    >
                      Unidades Atendidas
                      <i class="bx bx-buildings"></i>
                    </b-button>
                  </div>
                </b-col>
                <b-col> </b-col>
              </b-row>
              <b-row>
                <b-col class="col-encargos" md="auto">
                  <ChartEncargos :distributor="distributor"></ChartEncargos>
                </b-col>
                <b-col class="col-tusd">
                  <ChartTarifa :distributor="distributor"></ChartTarifa>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="card" v-if="isValue && !loading" centered>
      <div class="card-body">
        <!-- <b-button
          centered
          variant="success"
          v-b-toggle.accordion-1
          href="javascript: void(0);"
        >
          Pesquisa
          <i class="bx bx-search"></i>
        </b-button> -->

        <h3 class="text-left">
          {{ $t('regulated_market.tariff_search') }}
        </h3>

        <!-- <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel"> -->
        <b-card-body>
          <search-tariff-page />
        </b-card-body>
        <!-- </b-collapse> -->
      </div>
    </div>
  </main>
</template>

<script>
import ChartEncargos from '../../components/chartEncargos';
import ChartTarifa from '../../components/chartTarifa';
import PageHeader from '@/components/page-header';
import { formatCNPJ } from '../../utils/cnpjFormatter';
import SearchTariffPage from '../../features/regulatedMarket/SearchTariffPage.vue';
import VueToast from 'vue-toast-notification';
import dayjs from 'dayjs';
import Vue from 'vue';

import {
  computeQuadro,
  groupedFilteredSearch
} from './regulatedMarket.services';
import { i18n } from '../../main';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

Vue.use(VueToast);

dayjs.locale('pt-br');

export default {
  name: 'FreeMarketPage',
  components: {
    PageHeader,
    ChartEncargos,
    ChartTarifa,
    SearchTariffPage
  },
  data() {
    return {
      isValue: true,
      loading: false,
      title: i18n.tc('regulated_market.title'),
      groupedDistributors: [],
      unidadesAtendidasFields: [
        { key: 'apelido', label: 'Unidades' },
        {
          key: 'cnpj',
          label: 'CNPJ',
          formatter: (value) => {
            return formatCNPJ(value);
          }
        }
      ],
      modalContentData: null
    };
  },
  methods: {
    search() {
      this.loading = true;

      groupedFilteredSearch({})
        .then((groupedDistributors) => {
          this.loading = false;

          this.groupedDistributors = groupedDistributors.map((item) => {
            const quadroItem = computeQuadro(item);

            this.isValue = quadroItem.temValor;

            return quadroItem;
          });
        })
        .catch((error) => {
          console.log('ERRO: ', error);
          return (this.loading = false);
        });
    },
    refreshModalData(distributor) {
      this.modalContentData = distributor;
    }
  },

  computed: {
    breadcrumb: () => [
      {
        text: i18n.tc('regulated_market.title'),
        href: '/'
      },
      {
        text: i18n.tc('regulated_market.grouped_tariff_menu'),
        active: true
      }
    ]
  },
  created() {
    this.search();
  }
};
</script>

<style scoped>
.col-tusd,
.col-encargos {
  background-color: #fff;
  width: 48%;
}

@media (max-width: 768px) {
  .col-tusd,
  .col-encargos {
    background-color: #fff;
    width: 100%;
  }
}
</style>
